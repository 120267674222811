.ant-modal {
    .ant-modal-header {
        background-color: #708791 !important;

        .ant-modal-title {
            color: white;
            text-transform: capitalize;
        }
    }

    .ant-modal-close {
        color: white !important;
    }
}

.ant-card {
    overflow: hidden !important;

    .ant-card-body {
        @media only screen and (max-width: 420px) {
            padding: 12px !important;
        }
    }
}