.btn-verify {
    color: green;
    margin-left: 12px;
    margin-right: 12px;
}

.primary-btn {
    border-color: #708791 !important;
    background: #708791 !important;
    height: 48px !important;
    margin: 14px !important;
    border-radius: 12px !important;
}