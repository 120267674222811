.container {
    height: 100vh;
    background-color: white;

    .reset-password-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        .reset-password-header {
            display: flex;
            flex-direction: column;
            align-items: center;

            .logo {
                img {
                    height: 100px;
                    width: 120px;
                    margin: 10px;
                }

                h1 {
                    font-size: 20px;
                    opacity: 0.8;
                }
            }

            span {
                margin: 10px;
                font-weight: bold;
            }
        }

        .reset-password-error-message {
            text-align: center;
            color: red;
            font-style: italic;
        }

        .reset-password-form {
            min-height: 200px;
            min-width: 350px;
            max-width: 450px;
            padding: 10px;
            align-self: center;

            .title-header {
                text-align: center;

                h1 {
                    font-size: 42px;
                    font-weight: bold;
                }
            }

            h4 {
                font-weight: bold;
            }

            .reset-password-reminder {
                text-align: center;
                margin-top: 12px;

                p {
                    font-size: 12px;
                    color: #708791;
                }
            }


        }
    }
}