.ant-form-item {
    position: relative;

    .ant-form-item-label {
        position: absolute;
        z-index: 1;
        left: 10px;

        label {
            color: #108ee9;
            font-weight: 600;
            opacity: 0.8;
            font-size: 10px;
        }
    }

    .ant-picker-range {
        padding: 24px 11px 4px 20px !important;
        font-size: 14px;
        // margin-bottom: 12px;
    }
}