.ant-table table {
    border-spacing: 0 2px !important;
    // border-collapse: collapse !important;
    background-color: #d8e0e3 !important;
}

.ant-table-thead {
    background-color: #708791 !important;

    .ant-table-cell {
        color: white !important;
        background-color: transparent !important;
    }
}

table tr td {
    padding: 12px !important;
    background-color: white;
    font-size: 12px;
    color: gray;
    text-transform: capitalize;
}


table th {
    font-size: 14px;
    font-weight: bold;
    color: gray;
    opacity: 0.8;
}