.ant-layout {
    background: unset !important;
}

.d-flex {
    display: flex !important;
}

table {
    @include media(">=phone", "<lgphone") {
        span {
            font-size: 9px;
        }

        .ant-tag {
            font-size: 9px;
        }

        tr {
            td {
                padding: 6px !important;
            }
        }
    }
}


.ant-btn-primary {
    background-color: #708791;
    border-color: #708791;
}